/* styles.css */

body,
html {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-image: url(../../public/images/spices-tomatoes-near-spaghetti-garlic.jpg);

  background-size: 100vw 100vh;
  /* background-repeat: no-repeat; */
  text-align: center;
  /* color: #fccb06; */
  height: 100%;

  overflow-x: hidden; /* Hide horizontal scrollbar */
}
body {
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

header {
  background: #323131;
  color: #fff;
  padding: 1.5rem;
  width: 100%;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}

nav a {
  color: #fff;
  text-decoration: none;
}

main {
  flex: 1;
  /* padding: 1rem; */
  overflow-y: auto; /* Prevent scrolling within main content */
  overflow-x: hidden;
}

footer {
  background: #323131;
  color: #fff;
  text-align: center;
  padding: 0.5rem;
  width: 100%;
  flex-shrink: 0; /* Ensure footer doesn't shrink */
  /* position: relative; */
  /* bottom: 0; */
}

/* Specific styles for Home component and headings */
.home-header1 {
  color: yellow;
  font-size: 50px;
  animation: fadeIn 1s ease-out;
}

.home-header2 {
  color: green;
  font-size: 50px;
  margin-top: -30px;
  animation: fadeIn 1s ease-out;
}

/* General styles for div elements */
div {
  padding: 0rem;
}
/* home css */
.home-content {
  display: grid;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(255, 255, 255);
  background-color: white;
  font-weight: 600;
  border-radius: 10px;
  width: 60%;
  align-self: center;
  margin: auto;
  padding: 20px;
  background-image: url(../../public/images/dark-background-abstract-background-network-3d-background-3840x2160-8324.png);
  background-size: 100%;
  animation: fadeIn 1s ease-out;
}
/* styles.css */

.reviews-container {
  width: 100%;
  /* background-color: beige; */
  color: white;
  font-size: 13px;
  margin-top: 2rem;
  padding: 1rem;
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  /* background-color: #f5f5f5; */
  overflow-x: hidden; /* Enable horizontal scrolling */
  /* white-space: nowrap;  */
  font-family: Georgia, serif;
  animation: fadeIn 1s ease-out;
}
@keyframes scroll {
  0% {
    transform: translateX(0); /* Start from beginning */
  }
  100% {
    transform: translateX(calc(-100% - 1rem)); /* Move left (negative value) */
  }
}

.reviews-list {
  display: inline-flex; /* Display reviews containers in a row */
  gap: 3rem; /* Spacing between reviews */
  animation: scroll 20s linear infinite; /* CSS animation for scrolling */
}
.reviews-list:hover {
  animation-play-state: paused;
}

.review {
  min-width: 300px;
  min-height: auto;
  padding: 1rem;
  border: 1px solid gold;
  border-radius: 10px;
  background-image: url(../../public/images/spices-tomatoes-near-spaghetti-garlic.jpg);
}
.review:hover {
  transform: scale(1.1);
}
.review-heading {
  color: #fccb06;
}

/* menu css */
.Menu-heading {
  color: gold;
}
/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  max-width: 80%;
  background-image: url("../../public/images/pngtree-black-vegetable-line-background-image_508366.jpg");
  /* background-position: 50%; */
  background-size: 550px 850px;
  background-repeat: no-repeat;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 50px;
  color: #fff;
}
.menu-image {
  height: auto;
  width: 500px;
}
/* Menu.css */
.menu-categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.menu-category {
  cursor: pointer;
  padding: 20px;
  background: #f4f4f4;
  border-radius: 8px;
  text-align: center;
  transition: background 0.3s ease;
}

.menu-category:hover {
  background: #e2e2e2;
}
/* map.css */
.map-container {
  width: 100%;
  height: auto;
  margin: 20px 0;
  align-items: center;
  margin: auto;
  margin-bottom: 40px;
}

/* GooglePhotos.css */
.photos-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 400px; /* Set a fixed height to ensure the container is large enough for the arrows */

  margin-bottom: -20px;
  animation: fadeIn 1s ease-out;
}

.photo-viewer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.arrow-button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 30px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.prev-arrow {
  left: 10px;
}

.next-arrow {
  right: 10px;
}

.place-photo {
  max-width: 100%;
  max-height: 50%;
  margin: 0 40px; /* Add margin to ensure arrows are not too close to the image */
}

/* hooks.css */

/* .hidden {
  opacity: 0;
  transform: translateX(-100%); 
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.visible {
  opacity: 1;
  transform: translateX(0);
} */

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.menu-category {
  animation: fadeIn 1s ease-out;
}

/* Maps.css */
.google-map {
  animation: fadeIn 1s ease-out;
}

/* aboutus.css */
.aboutus-container {
  background-color: #161616;
  width: 80%;
  margin: 0 auto;
  border-radius: 3%;
  animation: fadeIn 1s ease-out;
  padding: 2%;
  margin-bottom: 4%;
}
.aboutus-heading2 {
  color: #eeba2c;
}
.aboutus-heading1 {
  color: yellow;
  animation: fadeIn 1s ease-out;
}
.aboutus-content {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #e2e2e2;
}

/* ContactUs.css */
.contactus-heading {
  animation: fadeIn 1s ease-out;
  color: yellow;
}
.contactus-container {
  animation: fadeIn 1s ease-out;
  background-color: #161616;
  padding: 2%;
  width: 80%;
  margin: 0 auto;
  border-radius: 3%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 4%;
}

.contactus-container h1 {
  text-align: center;
  color: yellow;
}

.contact-details {
  margin-bottom: 20px;
}

.contact-details h2 {
  color: #eeba2c;
}

.contact-details p {
  color: white;
  line-height: 1.6;
}

.contact-details a {
  color: white;
  text-decoration: none;
}

.contact-details a:hover {
  text-decoration: underline;
}

.contact-form {
  margin-top: 20px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  margin-bottom: 5px;
  color: white;
}

@media (max-width: 1000px) {
  body,
  html {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    background-image: url(../../public/images/spices-tomatoes-near-spaghetti-garlic.jpg);

    background-size: 100vw 100vh;
    /* background-repeat: no-repeat; */
    text-align: center;
    /* color: #fccb06; */
    height: 100%;

    overflow-x: hidden; /* Hide horizontal scrollbar */
  }
  body {
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }

  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  header {
    background: #323131;
    color: #fff;
    padding: 1.5rem;
    width: 100%;
  }

  nav ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin-right: 2rem;
    padding: 0;
  }

  nav a {
    color: #fff;
    text-decoration: none;
  }

  main {
    flex: 1;
    /* padding: 1rem; */
    overflow-y: auto; /* Prevent scrolling within main content */
    overflow-x: hidden;
  }

  footer {
    background: #323131;
    color: #fff;
    text-align: center;
    padding: 0.5rem;
    width: 100%;
    flex-shrink: 0; /* Ensure footer doesn't shrink */
    /* position: relative; */
    /* bottom: 0; */
  }

  /* Specific styles for Home component and headings */
  .home-header1 {
    color: yellow;
    font-size: 50px;
    animation: fadeIn 1s ease-out;
  }

  .home-header2 {
    color: green;
    font-size: 50px;
    margin-top: -30px;
    animation: fadeIn 1s ease-out;
  }

  /* General styles for div elements */
  div {
    padding: 0rem;
  }
  /* home css */
  .home-content {
    display: grid;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(155, 149, 149);
    background-color: white;
    border-radius: 10px;
    width: 60%;
    align-self: center;
    margin: auto;
    padding: 20px;
    background-image: url(../../public/images/dark-background-abstract-background-network-3d-background-3840x2160-8324.png);
    background-size: 100%;
    animation: fadeIn 1s ease-out;
  }
  /* styles.css */

  .reviews-container {
    width: 100%;
    /* background-color: beige; */
    color: white;
    font-size: 13px;
    margin-top: 2rem;
    padding: 1rem;
    /* border: 1px solid #ccc; */
    border-radius: 10px;
    /* background-color: #f5f5f5; */
    overflow-x: hidden; /* Enable horizontal scrolling */
    /* white-space: nowrap;  */
    font-family: Georgia, serif;
    animation: fadeIn 1s ease-out;
  }
  @keyframes scroll {
    0% {
      transform: translateX(0); /* Start from beginning */
    }
    100% {
      transform: translateX(
        calc(-100% - 1rem)
      ); /* Move left (negative value) */
    }
  }

  .reviews-list {
    display: inline-flex; /* Display reviews containers in a row */
    gap: 3rem; /* Spacing between reviews */
    animation: scroll 20s linear infinite; /* CSS animation for scrolling */
  }
  .reviews-list:hover {
    animation-play-state: paused;
  }

  .review {
    min-width: 300px;
    min-height: auto;
    padding: 1rem;
    border: 1px solid gold;
    border-radius: 10px;
    background-image: url(../../public/images/spices-tomatoes-near-spaghetti-garlic.jpg);
  }
  .review:hover {
    transform: scale(1.1);
  }
  .review-heading {
    color: #fccb06;
  }

  /* menu css */
  .Menu-heading {
    color: gold;
  }
  /* Modal.css */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 500px;
    max-width: 80%;
    background-image: url("../../public/images/pngtree-black-vegetable-line-background-image_508366.jpg");
    /* background-position: 50%; */
    background-size: 550px 850px;
    background-repeat: no-repeat;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 50px;
    color: #fff;
  }
  .menu-image {
    height: 500px;
    width: 320px;
  }
  /* Menu.css */
  .menu-categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .menu-category {
    cursor: pointer;
    padding: 20px;
    background: #f4f4f4;
    border-radius: 8px;
    text-align: center;
    transition: background 0.3s ease;
  }

  .menu-category:hover {
    background: #e2e2e2;
  }
  /* map.css */
  .map-container {
    width: 100%;
    height: auto;
    margin: 20px 0;
    align-items: center;
    margin: auto;
    margin-bottom: 40px;
  }

  /* GooglePhotos.css */
  .photos-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 400px; /* Set a fixed height to ensure the container is large enough for the arrows */

    margin-bottom: -20px;
    animation: fadeIn 1s ease-out;
  }

  .photo-viewer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .arrow-button {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 30px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .prev-arrow {
    left: 10px;
  }

  .next-arrow {
    right: 10px;
  }

  .place-photo {
    max-width: 100%;
    max-height: 50%;
    margin: 0 40px; /* Add margin to ensure arrows are not too close to the image */
  }

  /* hooks.css */

  /* .hidden {
  opacity: 0;
  transform: translateX(-100%); 
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.visible {
  opacity: 1;
  transform: translateX(0);
} */

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .menu-category {
    animation: fadeIn 1s ease-out;
  }

  /* Maps.css */
  .google-map {
    animation: fadeIn 1s ease-out;
  }

  /* aboutus.css */
  .aboutus-container {
    background-color: #161616;
    width: 80%;
    margin: 0 auto;
    border-radius: 3%;
    animation: fadeIn 1s ease-out;
    padding: 2%;
    margin-bottom: 4%;
  }
  .aboutus-heading2 {
    color: #eeba2c;
  }
  .aboutus-heading1 {
    color: yellow;
    animation: fadeIn 1s ease-out;
  }
  .aboutus-content {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    color: #e2e2e2;
  }

  /* ContactUs.css */
  .contactus-heading {
    animation: fadeIn 1s ease-out;
    color: yellow;
  }
  .contactus-container {
    animation: fadeIn 1s ease-out;
    background-color: #161616;
    padding: 2%;
    width: 80%;
    margin: 0 auto;
    border-radius: 3%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 4%;
  }

  .contactus-container h1 {
    text-align: center;
    color: yellow;
  }

  .contact-details {
    margin-bottom: 20px;
  }

  .contact-details h2 {
    color: #eeba2c;
  }

  .contact-details p {
    color: white;
    line-height: 1.6;
  }

  .contact-details a {
    color: white;
    text-decoration: none;
  }

  .contact-details a:hover {
    text-decoration: underline;
  }

  .contact-form {
    margin-top: 20px;
  }

  .contact-form form {
    display: flex;
    flex-direction: column;
  }

  .contact-form label {
    margin-bottom: 5px;
    color: white;
  }
}
